/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import BackgroundImage from 'gatsby-background-image'

// Styles
import 'styles/CommunityTemplate.scss'

// Images
import officeIcon from 'img/office.svg'
import fieldIcon from 'img/field.svg'
import chevronDown from 'img/chevron-down.svg'
import quoteGreenIcon from 'img/quote-green.svg'
import quoteBlueIcon from 'img/textballoon.svg'
import CheckMark from 'img/check_mark.svg'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Banner from 'components/Banner'
import Content from 'components/Content'
import { BackgroundColor } from 'components/Elements'
import EmployeeSlider from 'components/EmployeeSlider'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const Separator = styled.div`
  height: 2px;
`

const ContentContainer = styled.div``

const ExtraValue = styled.p``

const BrandwiseCommunity = ({ data, pageContext }) => {
  const {
    wordpressPage: {
      title,
      acf: {
        hero,
        after_hero: afterHero,
        mission_group: missionGroup,
        brandwise_academy: brandwiseAcademy,
        before_footer: beforeFooter,
      },
    },
  } = data

  function addGradient(imageProp) {
    const gradient = [
      imageProp,
      `linear-gradient(to left, rgba(0, 53, 120, 0) 32%, #001843)`,
    ].reverse()

    return gradient
  }

  return (
    <Layout>
      <SEO title={title} />
      <div className="brandwise-community">
        <section className="hero">
          <div className="position-relative">
            <div className="hero-overlay" />
            <Banner image={hero.image} />
          </div>

          <div className="container">
            <div className="hero-after">
              <div
                className="hero-after-breadcrumb breadcrumb-white empty-space-50 pl-5 color-background-main d-flex align-items-center"
              >
                <Breadcrumb crumbSeparator={' > '} crumbs={pageContext.breadcrumb.crumbs} crumbLabel={title} />
              </div>

              <div className="hero-after-card px-5 py-4 color-background-light card-shadow">
                <div className="row">
                  <div className="col-12">
                    <h2 className="color-text-contrast font-size-ms font-weight-xl mb-0">{hero.subtitle}</h2>
                    <h1 className="font-weight-xl font-size-h1 mb-4">{hero.title}</h1>
                  </div>
                  <div className="col-lg-7 pr-4">
                    <Content content={hero.description} />
                  </div>
                  <div className="col-lg-5">
                    <div className="row justify-content-center">
                      <div className="col-6 d-flex flex-column align-items-center">
                        <img className="mb-3" src={officeIcon} alt="" />
                        <Content
                          className="color-text-contrast line-height-s nested text-center font-weight-l"
                          content={hero.office}
                        />
                      </div>
                      <div className="col-6 d-flex flex-column align-items-center">
                        <img className="mb-3 empty-space-50" src={fieldIcon} alt="" />
                        <Content
                          className="color-text-contrast line-height-s nested text-center font-weight-l"
                          content={hero.field}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-center">
                    <a id="more" href="#more">
                      <img src={chevronDown} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="after-hero pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <Content className="px-3 px-sm-5 text-justify" content={afterHero.content} />
              </div>

              <div className="col-lg-6">
                <Collage
                  imageOne={afterHero.collage[0]}
                  imageTwo={afterHero.collage[1]}
                  imageThree={afterHero.collage[2]}
                  imageFour={afterHero.collage[3]}
                />
              </div>
            </div>
          </div>
        </section>

        <BackgroundColor center className="academy color-background-light pb-5 pt-4">
          <div className="col-lg-6">
            <h2 className="font-weight-xl pb-3">{missionGroup.mission_label}</h2>
            <ContentContainer className="pr-4">
              {parse(missionGroup.mission)}
            </ContentContainer>
          </div>
          <div className="col-lg-6">
            <h2 className="font-weight-xl pb-3">{missionGroup.core_value_label}</h2>
            {missionGroup.core_values.map((core, coreIndex) => (
              <div key={coreIndex} className="d-flex flex-wrap align-items-center pb-3">
                <img className="pr-3" src={CheckMark} alt="" />
                <p className="m-0 text-uppercase font-weight-xl font-size-xm color-text-main">{core.core_value}</p>
                <div className="w-100 pl-md-5 ml-4 d-flex">
                  {core.extra_values.map((extra, extraIndex) => (
                    <>
                      <ExtraValue key={extraIndex} className="m-0 pr-2">{extra.extra_value}</ExtraValue>
                      {extraIndex + 1 < core.extra_values.length && (<strong className="pr-2">|</strong>)}
                    </>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Separator className="col-12 d-flex color-background-main my-5" />
          <div className="col-12 pt-5">
            <h2 className="text-center font-weight-xl font-size-h1 pb-3 mb-4">{brandwiseAcademy.title}</h2>
            <Content className="mb-5" content={brandwiseAcademy.description} />
          </div>

          {brandwiseAcademy.testimonials.map((testimonial, key) => (
            <div key={key} className="col-lg-6 pb-4 pb-lg-0">
              <div
                className={`${key === 0 ? `color-background-main` : `color-background-contrast`} d-flex flex-column align-items-start px-4 py-4 h-100 justify-content-between`}
              >
                <img className="mb-3" src={key === 0 ? quoteGreenIcon : quoteBlueIcon} alt="" />
                <Content className="nested w-100 h-100 color-text-light" content={testimonial.quote} />
                <div className="w-100 d-flex font-family-main nested flex-column align-items-end mt-0">
                  <strong className="color-text-light">{testimonial.name}</strong>
                  <p className="mb-0 font-weight-roman color-text-light font-italic">{testimonial.function}</p>
                </div>
              </div>
            </div>
          ))}

          {/* <div className="col-10">
            <h2 className="font-weight-xl font-size-h1 mt-5 mb-4">
              {brandwiseAcademy.employee_slider_title}
            </h2>

            <EmployeeSlider />
          </div> */}
        </BackgroundColor>

        <BackgroundImage
          fluid={addGradient(beforeFooter.image.localFile.childImageSharp.fluid)}
          className="background-banner"
          tag="section"
        >
          <div className="container cta-banner py-5">
            <div className="mb-lg-5">
              <Content className="nested font-weight-xl color-text-light" content={beforeFooter.title} />
              <strong className="color-text-contrast font-size-ms">{beforeFooter.subtitle}</strong>
            </div>

            <a href={beforeFooter.link.url} className="button button-alt px-3 mt-2">
              {beforeFooter.link.title}
            </a>
          </div>
        </BackgroundImage>
      </div>
    </Layout>
  )
}

export default BrandwiseCommunity

const Collage = ({ imageOne, imageTwo, imageThree, imageFour }) => (
  <div className="collage">
    <div className="d-flex justify-content-center flex-wrap">
      <BackgroundImage
        fluid={imageOne.image.localFile.childImageSharp.fluid}
        className="w-50 d-flex align-items-end collage-top collage-top--left"
      >
        {/* <span className="ml-2 mb-2 px-2 font-size-sm font-weight-s color-background-main text-white">
          {imageOne.title}
        </span> */}
      </BackgroundImage>

      <BackgroundImage
        fluid={imageTwo.image.localFile.childImageSharp.fluid}
        className="w-50 d-flex align-items-end collage-top collage-top--right"
      >
        {/* <span className="ml-2 mb-2 px-2 font-size-sm font-weight-s color-background-main text-white">
          {imageTwo.title}
        </span> */}
      </BackgroundImage>

      <BackgroundImage
        fluid={imageThree.image.localFile.childImageSharp.fluid}
        className="d-flex align-items-end collage-bottom collage-bottom--left"
      >
        {/* <span className="ml-2 mb-2 px-2 font-size-sm font-weight-s color-background-main text-white">
          {imageThree.title}
        </span> */}
      </BackgroundImage>

      <BackgroundImage
        fluid={imageFour.image.localFile.childImageSharp.fluid}
        className="d-flex align-items-end collage-bottom collage-bottom--right"
      >
        {/* <span className="ml-2 mb-2 px-2 font-size-sm font-weight-s color-background-main text-white">
          {imageFour.title}
        </span> */}
      </BackgroundImage>
    </div>
  </div>
)


export const pageQuery = graphql`
	query BrandwiseCommunityQuery($wordpress_id: Int!) {
		wordpressPage(wordpress_id: { eq: $wordpress_id }) {
			id
			wordpress_id
			title
			slug
			acf {
				hero {
					image {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 1920) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					title
					subtitle
					description
					office
					field
				}

				after_hero {
					content
					collage {
						title
						image {
							localFile {
								childImageSharp {
									fluid(quality: 100, maxWidth: 250) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}

        mission_group {
          mission_label
          mission
          core_value_label
          core_values {
            core_value
            extra_values {
              extra_value
            }
          }
        }

				brandwise_academy {
					title
					description
					testimonials {
						quote
						name
						function
					}
					employee_slider_title
				}

				before_footer {
					image {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 1920) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					title
					subtitle
					link {
						url
						title
					}
				}

			}
		}
	}
`